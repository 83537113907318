import React from 'react';

function PaymentInfo() {
  return (
    <div className="border-2 border-gainsboro b-3 md:mb-6 px-3 py-2 rounded-lg">
      <p className="leading-relaxed text-slategray text-sm">
        Po przejściu dalej zostaniesz przekierowany do systemu Przelewy24, gdzie
        możesz opłacić zamówienie za pomocą BLIK lub płatności online.
      </p>
    </div>
  );
}

export default PaymentInfo;
