import React from 'react';
import classNames from 'classnames';

function Radio({ disabled, register, name, value, children, ...rest }) {
  const labelClass = classNames('block flex items-center', {
    'cursor-not-allowed': disabled,
    'cursor-pointed': !disabled,
  });

  const inputClass = classNames('mr-3 leading-tight', {
    'cursor-not-allowed opacity-50': disabled,
  });

  const htmlID = name + '_' + value;

  return (
    <label className={labelClass} htmlFor={htmlID}>
      <input
        id={htmlID}
        name={name}
        type="radio"
        ref={register}
        className={inputClass}
        disabled={disabled}
        value={value}
        {...rest}
      />
      <span className="text-sm text-slategray flex w-full justify-between">
        {children}
      </span>
    </label>
  );
}

export default Radio;
