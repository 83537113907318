import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { useForm, FormContext } from 'react-hook-form';
import { useCart } from 'react-use-cart';
import { toast } from 'react-toastify';
import { FirebaseContext } from '../Firebase';

import BillingForm from './BillingForm';
import PaymentForm from './PaymentForm';
import ShippingForm from './ShippingForm';
import CheckoutContext from '../../context/Checkout';

function CheckoutForm() {
  const methods = useForm({
    defaultValues: {
      separateBilling: false,
      shippingMethod: 'inpost',
      billing: {
        country: 'PL',
      },
      shipping: {
        country: 'PL',
      },
    },
  });
  const { handleSubmit, watch, errors } = methods;

  const { items } = useCart();
  const { separateBilling } = watch();
  const {
    checkoutError,
    checkoutProcessing,
    checkoutSuccess,
    orderTotal,
    getShippingMethods,
  } = useContext(CheckoutContext);
  // const stripe = useStripe();
  // const elements = useElements();

  const { firebase } = useContext(FirebaseContext);

  const useSeparateBilling = !!separateBilling;

  const handleCheckoutError = ({
    message = 'Unable to process order. Please try again',
  }) => {
    checkoutError({ message });

    toast.error(message, {
      className: 'bg-red',
    });
  };

  const submitOrder = async values => {
    // Show we're working on it
    checkoutProcessing();

    try {
      // Prepare data
      const {
        email,
        phone,
        shipping: { first_name, last_name, ...rest },
        billing: billingAddress,
        shippingMethod,
      } = values;

      const checkoutItems = items.map(
        ({ id: variantId, description, image, ...rest }) => ({
          variantId,
          ...rest,
        })
      );

      const shippingAddress = { first_name, last_name, ...rest };

      const input = {
        first_name,
        last_name,
        email,
        phone,
        total: orderTotal,
        shipping: shippingAddress,
        billing: useSeparateBilling ? billingAddress : shippingAddress,
        items: checkoutItems,
        shippingMethod: getShippingMethods().find(s => s.key === shippingMethod)
          .id,
      };

      // Create order in woo
      firebase.createOrder(input).then(response => {
        // Get link to acquirer
        if (response.data.status === 201) {
          navigate(response.data.paymentUrl);
          // handleCheckoutSuccess(response.data.id);
        } else {
          handleCheckoutError({
            message: 'Coś poszło nie tak. Spróbuj ponownie później.',
          });
        }

        // Redirect
      });
    } catch (err) {
      handleCheckoutError(err);
    }
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(submitOrder)}>
        <ShippingForm />
        {useSeparateBilling && <BillingForm />}
        <PaymentForm />
      </form>
    </FormContext>
  );
}

export default CheckoutForm;
