import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCart } from 'react-use-cart';
// import classnames from 'classnames';

// import LoadingSVG from '../../svg/loading.svg';

import Radio from '../Radio';
import Input from '../Input';
// import Select from '../Select';
import Checkbox from '../Checkbox';
import CheckoutContext from '../../context/Checkout';

// import usePrintfulShippingCountries from '../../hooks/usePrintfulShippingCountries';

function ShippingForm() {
  const { errors, register, watch, setValue } = useFormContext();
  // const shippingCountries = [{ code: 'PL' }];
  const {
    allowPayment,
    processing: checkoutProcessing,
    updateShipping,
    updateShippingMethod,
    getShippingMethods,
  } = useContext(CheckoutContext);

  const [onlyDownloadable, setOnlyDownloadable] = useState(false);

  const { shippingMethod } = watch({
    nest: true,
  });

  const { items } = useCart();

  // Check if downloadable only, then set shipping to pickup
  useEffect(() => {
    const onlyDownloadable = items.reduce((acc, item) => {
      if (item.downloadable === false) {
        acc = false;
      }

      return acc;
    }, true);

    if (onlyDownloadable === true) {
      setValue('shippingMethod', 'pickup');
      setOnlyDownloadable(true);
    }
  }, [items]);

  // Update shipping price
  useEffect(() => {
    console.log(
      'current shipping method',
      shippingMethod,
      getShippingMethods()
    );
    updateShippingMethod(shippingMethod);

    updateShipping(
      getShippingMethods().find(method => method.key === shippingMethod).price
    );
  }, [shippingMethod]);

  // const activeShippingCountry = shippingCountries.find(
  //   country => country.code === shippingCountryCode
  // );

  const disableInput = allowPayment || checkoutProcessing;

  return (
    <>
      <div
        className={
          'rounded-lg bg-white border-2 border-gainsboro p-3 md:p-6 my-3 md:my-6 ' +
          (onlyDownloadable && ' hidden ')
        }
      >
        <h3 className="text-slategray text-2xl md:text-4xl font-bold mb-6">
          Sposób dostawy
        </h3>

        {getShippingMethods().map(method => (
          <div key={method.key} className="mb-3 md:mb-6">
            <Radio
              name="shippingMethod"
              disabled={false}
              register={register}
              value={method.key}
            >
              {method.name} {method.timing && <>({method.timing})</>}
              <span className="self-end">+{method.price} zł</span>
            </Radio>
          </div>
        ))}
      </div>

      <div className="rounded-lg bg-white border-2 border-gainsboro p-3 md:p-6 my-3 md:my-6">
        <h3 className="text-slategray text-2xl md:text-4xl font-bold mb-6">
          {shippingMethod === 'pickup' ? 'Dane kupującego' : 'Adres dostawy'}
        </h3>

        <div className="md:flex -mx-3">
          <div className="md:w-1/2 px-3 mb-3 md:mb-6">
            <Input
              name="shipping.first_name"
              placeholder="Imię"
              disabled={disableInput}
              register={register({ required: 'To pole jest wymagane' })}
              errors={errors}
            />
          </div>
          <div className="md:w-1/2 px-3 mb-3 md:mb-6">
            <Input
              name="shipping.last_name"
              placeholder="Nazwisko"
              disabled={disableInput}
              register={register({ required: 'To pole jest wymagane' })}
              errors={errors}
            />
          </div>
        </div>

        <div className="md:flex -mx-3">
          <div className="md:w-1/2 mb-3 md:mb-6 px-3">
            <Input
              name="email"
              type="email"
              placeholder="E-mail"
              disabled={disableInput}
              register={register({
                required: 'E-mail jest wymagany',
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: 'E-mail jest nieprawidłowy',
                },
              })}
              errors={errors}
            />
          </div>

          <div className="md:w-1/2 mb-3 md:mb-6 px-3">
            <Input
              name="phone"
              type="tel"
              placeholder="Telefon"
              disabled={disableInput}
              register={register}
              errors={errors}
            />
          </div>
        </div>
        {shippingMethod !== 'pickup' && (
          <>
            <div className="mb-3 md:mb-6">
              <Input
                name="shipping.address1"
                placeholder="Adres"
                disabled={disableInput}
                register={register({
                  required: 'Adres dostawy jest wymagany',
                })}
                errors={errors}
              />
            </div>

            <div className="mb-3 md:mb-6">
              <Input
                name="shipping.address2"
                placeholder="Dodatkowy adres"
                disabled={disableInput}
                register={register}
                errors={errors}
              />
            </div>

            <div className="md:flex -mx-3">
              <div className="md:w-1/4 mb-3 md:mb-6 px-3">
                <Input
                  name="shipping.zip"
                  placeholder="Kod pocztowy"
                  disabled={disableInput}
                  register={register({ required: 'To pole jest wymagane' })}
                  errors={errors}
                />
              </div>
              <div className="md:w-3/4 mb-3 md:mb-6 px-3">
                <Input
                  name="shipping.city"
                  placeholder="Miejscowość"
                  disabled={disableInput}
                  register={register({ required: 'To pole jest wymagane' })}
                  errors={errors}
                />
              </div>
            </div>
          </>
        )}

        <div className="md:flex -mx-3">
          <div className="mb-3 md:mb-6 px-3">
            <Checkbox
              name="terms"
              disabled={disableInput}
              register={register({ required: 'To pole jest wymagane' })}
              errors={errors}
            >
              Akceptuję 
              <a target="_blank" href="/regulamin">
                regulamin
              </a>
              *
            </Checkbox>
          </div>
        </div>

        <div className="md:flex -mx-3">
          <div className="mb-3 md:mb-6 px-3">
            <Checkbox
              name="privacy_policy"
              disabled={disableInput}
              register={register({ required: 'To pole jest wymagane' })}
              errors={errors}
            >
              Wyrażam zgodę na przetwarzanie moich osobowych w celu realizacji
              zamówienia. Więcej informacji w{' '}
              <a target="_blank" href="/polityka-prywatnosci">
                polityce prywatności
              </a>
              *
            </Checkbox>
          </div>
        </div>

        {!allowPayment && (
          <div className="flex items-center justify-between">
            {/* <Checkbox
              name="separateBilling"
              disabled={disableInput}
              register={register}
            >
              Use different billing address
            </Checkbox> */}
            {/* <button
              type="submit"
              className={classnames(
                'bg-primary rounded-lg text-white px-3 py-2 h-10 focus:outline-none font-bold',
                { 'cursor-not-allowed opacity-50': disableInput }
              )}
              disabled={disableInput}
            >
              {checkoutProcessing ? <LoadingSVG /> : 'Calculate shipping'}
            </button> */}
          </div>
        )}
      </div>
    </>
  );
}

export default ShippingForm;
