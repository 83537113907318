import React, { useContext } from 'react';
import { useCart } from 'react-use-cart';
import { useFormContext } from 'react-hook-form';

import CheckoutItem from './CheckoutItem';
import CheckoutContext from '../context/Checkout';

function CheckoutItemList() {
  const { items, cartTotal } = useCart();
  const {
    orderTotal,
    shipping,
    tax,
    shippingMethod,
    getShippingMethods,
  } = useContext(CheckoutContext);

  const activeShippingMethod = getShippingMethods().find(
    method => method.key === shippingMethod
  );

  const formatValue = value =>
    new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
    }).format(value);

  return (
    <div className="rounded bg-white border-2 border-gainsboro p-3 md:p-6 my-3 md:my-6">
      {items.map(CheckoutItem)}
      <div className="flex items-center justify-between">
        <span className="text-sm text-slategray">Suma</span>
        <span className="font-semibold">{formatValue(cartTotal)}</span>
      </div>
      <div className="flex items-center justify-between">
        <span className="text-sm text-slategray">Podatek</span>
        <span className="font-semibold">{tax ? formatValue(tax) : '-'}</span>
      </div>
      <div className="flex items-center justify-between">
        <span className="text-sm text-slategray">
          Dostawa: {activeShippingMethod && activeShippingMethod.name}
        </span>
        <span className="font-semibold">
          {shipping ? formatValue(shipping) : '-'}
        </span>
      </div>
      <hr className="border-b border-gainsboro my-3 w-20" />
      <div className="flex items-center justify-between">
        <span className="text-lg font-semibold text-slategray">Razem</span>
        <span className="text-xl font-bold text-success">
          {formatValue(orderTotal)}
        </span>
      </div>
    </div>
  );
}

export default CheckoutItemList;
