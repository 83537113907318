import React from 'react';

import SEO from '../components/SEO';
import Checkout from '../components/Checkout';
import { FirebaseLayout } from '../components/Firebase';

import Layout from '../components/Layout';

function CheckoutPage() {
  return (
    <Layout shop={true}>
      <FirebaseLayout>
        <SEO pageTitle="Zamówienie" />
        <div className="mb-6 pt-20 px-6">
          <h1 className="font-bold text-3xl md:text-6xl mb-3 text-white">
            Zamówienie
          </h1>
          <hr className="border-b border-gainsboro w-10" />
        </div>
        <Checkout />
      </FirebaseLayout>
    </Layout>
  );
}

export default CheckoutPage;
